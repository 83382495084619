import { Field, Formik } from "formik";
import { Button, Card, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { AnnouncementCodeRetrievalInput, CodeType } from "../../types";
import { nl } from "yup-locales";
import { useState } from "react";

Yup.setLocale(nl);

const validationSchema = Yup.object().shape({
    announcementCode: Yup.string().required().label("Code"),
});

const Index = () => {
    const navigate = useNavigate();
    const [type, setType] = useState<CodeType>();
    const handleSubmit = (input: AnnouncementCodeRetrievalInput) => {
        if (input.type == CodeType.customer) {
            navigate(`/announcement?code=${input.announcementCode}`);
        } else if (input.type == CodeType.maintenanceFirm) {
            navigate(`/onderhoudsfirma/${input.announcementCode}`);
        } else if (input.type == CodeType.reminderOf) {
            navigate(`/ofreminder/${input.announcementCode}`);
        } else if (input.type == CodeType.reminderOwner) {
            navigate(`/ownerreminder/${input.announcementCode}`);
        }
    };

    const initialValues = {
        announcementCode: "",
        type: type,
    };

    return (
        <Row className="mt-5">
            {!type ? (
                <div className="d-flex flex-row bd-highlight mb-3 justify-content-center">
                    <Card style={{ width: "18rem", textAlign: "center" }}>
                        <Card.Body>
                            <Card.Title>Onderhoudsfirma</Card.Title>
                            <Button
                                variant="primary"
                                onClick={() => setType(CodeType.maintenanceFirm)}
                            >
                                Open aankondiging
                            </Button>
                        </Card.Body>
                    </Card>
                    <Card
                        style={{ width: "18rem", textAlign: "center" }}
                        className="ms-3"
                    >
                        <Card.Body>
                            <Card.Title>Aankondiging klant</Card.Title>
                            <Button
                                variant="primary"
                                onClick={() => setType(CodeType.customer)}
                            >
                                Open aankondiging
                            </Button>
                        </Card.Body>
                    </Card>
                    <Card
                        style={{ width: "18rem", textAlign: "center" }}
                        className="ms-3"
                    >
                        <Card.Body>
                            <Card.Title>Herinneringen OF</Card.Title>
                            <Button
                                variant="primary"
                                onClick={() => setType(CodeType.reminderOf)}
                            >
                                Open herinnering
                            </Button>
                        </Card.Body>
                    </Card>
                    <Card
                        style={{ width: "18rem", textAlign: "center" }}
                        className="ms-3"
                    >
                        <Card.Body>
                            <Card.Title>Herinneringen Owner</Card.Title>
                            <Button
                                variant="primary"
                                onClick={() => setType(CodeType.reminderOwner)}
                            >
                                Open herinnering
                            </Button>
                        </Card.Body>
                    </Card>
                </div>
            ) : (
                <>
                    <div className="d-flex">
                        <Button variant="secondary" onClick={() => setType(undefined)}>
                            Terug
                        </Button>
                    </div>

                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        {({ handleSubmit, errors, touched }) => (
                            <Form className="mt-2" onSubmit={handleSubmit}>
                                <>
                                    <div className="mb-1">
                                        <label className="form-label" htmlFor="password">
                                            {type === CodeType.customer && (
                                                <span>
                                                    Voer de afspraakcode in die u in de mail heeft
                                                    ontvangen
                                                </span>
                                            )}
                                            {type === CodeType.maintenanceFirm && (
                                                <span>
                                                    Voer de unieke code in die u in de mail heeft
                                                    ontvangen om de lijst te openen
                                                </span>
                                            )}
                                            {type === CodeType.reminderOf && (
                                                <span>
                                                    Voer de unieke code in die u in de mail heeft
                                                    ontvangen om de lijst te openen
                                                </span>
                                            )}
                                            {type === CodeType.reminderOwner && (
                                                <span>
                                                    Open de link die u in de mail heeft ontvangen
                                                    om de status van uw lift door te geven.
                                                </span>
                                            )}
                                        </label>
                                        <Field
                                            className="form-control"
                                            name="announcementCode"
                                            type="text"
                                        />

                                        {errors.announcementCode && touched.announcementCode ? (
                                            <div className="text-danger">
                                                {errors.announcementCode}
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="text-end mt-4">
                                        <Button className="m-font-white" type="submit">
                                            Afspraak ophalen
                                        </Button>
                                    </div>
                                </>
                            </Form>
                        )}
                    </Formik>
                </>
            )}
        </Row>
    );
};

export default Index;
