import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQueryWithAuth = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
});

export const apiGenerator = createApi({
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({}),
});

export const {} = apiGenerator;
