import { useFormikContext } from "formik";
import { RadioButtonGroupItem } from "../../types";
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import * as _ from "lodash";
import AppFormError from "./AppFormError";

interface RadioButtonInputGroupProps {
  radioOptions: RadioButtonGroupItem[];
  identifier: string;
  getItemColor: (selectedRadioValue?: number) => string;
  disabled?: boolean;
  callback?: () => void;
}

const RadioButtonInputGroup = ({
  radioOptions,
  identifier,
  getItemColor,
  disabled,
  callback,
}: RadioButtonInputGroupProps) => {
  const { setFieldValue, values, errors, touched } = useFormikContext();

  const touchedConverted = touched as any;
  const errorsConverted = errors as any;

  return (
    <>
      <ToggleButtonGroup
        value={_.get(values, identifier)}
        type="radio"
        name={identifier}
        className="bg-white"
      >
        {radioOptions.map((radio, idx) => {
          return (
            <ToggleButton
              disabled={disabled}
              key={`${identifier}-${idx}`}
              id={`${identifier}-${idx}`}
              type="radio"
              variant={getItemColor(radio.value)}
              name="radio"
              value={radio.value}
              data-value={radio.value}
              checked={_.get(values, identifier) === radio.value}
              onClick={(e) => {
                const selectedValue = (e.target as any).dataset.value;

                // eslint-disable-next-line eqeqeq
                if (selectedValue == _.get(values, identifier)) {
                  setFieldValue(identifier, null);
                } else {
                  setFieldValue(identifier, parseInt(selectedValue) || 0);
                }

                if (callback) {
                  callback();
                }
              }}
            >
              {radio.name}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
      <AppFormError
        visible={touchedConverted[identifier]}
        error={errorsConverted[identifier]}
      ></AppFormError>
    </>
  );
};

export default RadioButtonInputGroup;
