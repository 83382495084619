import PageTitle from "../../components/PageTitle";
import * as Yup from "yup";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { Field, Formik } from "formik";
import { AnnouncementConfirmationInput, AppointmentDetails } from "../../types";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../utility/hooks/useQuery";
import { useEffect, useState } from "react";
import Search from "../../assets/search.svg";
import appointmentApi from "../../api/appointment";
import moment from "moment";
import "moment/locale/nl";

const validationSchema = Yup.object().shape({
  notes: Yup.string().required().label("Aanmeldinformatie"),
});

const Announcement = () => {
  moment.locale("nl");

  const navigate = useNavigate();

  const query = useQuery();
  const identifier = query.get("code");
  const [fetchingData, setFetchingData] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [data, setData] = useState<AppointmentDetails[]>();
  const [processing, setProcessing] = useState(false);

  const getData = async () => {
    await appointmentApi
      .getAppointmentDetails(identifier!)
      .then((result) => {
        const resultObject = result.data.data as AppointmentDetails[];

        setData(resultObject);
        setIsValid(true);

        setFetchingData(false);
      })
      .catch((result) => {
        setFetchingData(false);
        setIsValid(false);
        setProcessing(false);
      });
  };

  useEffect(() => {
    setFetchingData(true);
    getData();
  }, []);

  const handleSubmit = async (data: AnnouncementConfirmationInput) => {
    console.log(data);
    setProcessing(true);

    await appointmentApi
      .confirmAppointmentDetails(data)
      .then((result) => {
        //TODO: handle errors in result
        navigate("/announcement/confirmation");
      })
      .catch(() => {
        //TODO: handle errors
        setProcessing(false);
      });
  };

  const initialValues: AnnouncementConfirmationInput = {
    notes: "",
    identifier: identifier!,
  };

  if (fetchingData) {
    return (
      <>
        <Row className="mt-5 p-4">
          <Col className="text-center">
            <img
              className="img-fluid"
              src={Search}
              alt="Afspraakgegevens opzoeken..."
              width={100}
            />
          </Col>
        </Row>

        <Row>
          <Col className="text-center">
            <p className="h4">
              <strong>
                Een klein momentje, de afspraakgegevens worden opgehaald...
              </strong>
            </p>
          </Col>
        </Row>
      </>
    );
  }

  if (!fetchingData && !isValid) {
    return (
      <Row className="mt-5">
        <p>
          Oeps, deze link werkt niet meer. Dit kan betekenen dat de link
          verlopen is.
        </p>
        <p>
          Denkt u dat deze link wel zou moeten werken? Neem dan contact op met
          Chex via <strong>planning@chex.nl</strong>.
        </p>

        <p>U kunt deze pagina nu sluiten.</p>
      </Row>
    );
  }

  if (processing) {
    return <h2>De data wordt verwerkt...</h2>;
  }

  return (
    <>
      <PageTitle
        title="Bevestig uw afspraak"
        subTitle="Vul de onderstaande gevraagde gegevens in en druk op de versturen knop om de geplande afspraak te bevestigen"
      />

      <h3>De afspraakgegevens:</h3>

      {data?.map((appointment) => {
        return (
          <table className="table table-sm">
            <tbody>
              <>
                <tr>
                  <td className="appointment-table-record-width">Datum</td>
                  <td>{moment(appointment.date).format("LL")}</td>
                </tr>
                <tr>
                  <td>Inspecteur</td>
                  <td>{appointment.inspectorName}</td>
                </tr>
                <tr>
                  <td>Adres</td>
                  <td>
                    {appointment.address} {appointment.houseNumber},{" "}
                    {appointment.postalCode}, {appointment.city}
                  </td>
                </tr>

                {appointment.objectTypeSummaries.map((objectTypeSummary) => {
                  <tr>
                    <td>Installatie en keuringstype</td>
                    <td>{objectTypeSummary}</td>
                  </tr>;
                })}
              </>
            </tbody>
          </table>
        );
      })}

      <Row>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, errors, touched }) => (
            <Form className="mt-2" onSubmit={handleSubmit}>
              <>
                <Alert variant="warning">
                  <span className="text-black font-weight-bold">
                    Let op: onderstaande veld niet gebruiken om wijzigingen in
                    tijd en/of datum door te geven
                  </span>
                </Alert>

                <div className="mb-1">
                  <label className="form-label" htmlFor="password">
                    Waar en bij wie kan de inspecteur zich melden?
                  </label>
                  <Field
                    as="textarea"
                    className="form-control"
                    id="notes"
                    name="notes"
                  />

                  {errors.notes && touched.notes ? (
                    <div className="text-danger">{errors.notes}</div>
                  ) : null}
                </div>

                <div className="text-end mt-4">
                  <Button className="m-font-white" type="submit">
                    Afspraak bevestigen
                  </Button>
                </div>
              </>
            </Form>
          )}
        </Formik>
      </Row>
    </>
  );
};

export default Announcement;
