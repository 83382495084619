import CustomerAnnouncementLayout from "../../components/layouts/CustomerAnnouncementLayout";

const NoDataFound = () => {
  return (
    <CustomerAnnouncementLayout>
      <div className="mt-5">
        <p>
          Oeps, op het moment zijn er geen liftinstallaties gevonden die niet ingepland kunnen worden voor een keuring.
        </p>
        <p>
          Denkt u dat dit niet het geval is? Neem dan contact op met
          Chex via <strong>planning@chex.nl</strong>.
        </p>

        <p>U kunt deze pagina nu sluiten.</p>
      </div>
    </CustomerAnnouncementLayout>
  );
};

export default NoDataFound;
