import CustomerAnnouncementLayout from "../../components/layouts/CustomerAnnouncementLayout";

const NoDataFound = () => {
  return (
    <CustomerAnnouncementLayout>
      <div className="mt-5">
        <p>
          Oeps, deze link werkt niet meer. Dit kan betekenen dat de link
          verlopen is of dat deze niet correct is.
        </p>
        <p>
          Denkt u dat deze link wel zou moeten werken? Neem dan contact op met
          Chex via <strong>planning@chex.nl</strong>.
        </p>

        <p>U kunt deze pagina nu sluiten.</p>
      </div>
    </CustomerAnnouncementLayout>
  );
};

export default NoDataFound;
