import { useFormikContext } from "formik";
import AppFormError from "./AppFormError";
import * as _ from "lodash";
import TextElement from "./controls/TextElement";

function AppTextInput({ name, label = "", required = false, ...otherProps }) {
  const { setFieldTouched, setFieldValue, values, errors, touched } =
    useFormikContext();

  const onChange = (event) => {
    const inputType = event.target.type;

    if (inputType === "number") {
      const targetValue = event.target.value.replace(",", ".");
      setFieldValue(name, targetValue);
    } else {
      setFieldValue(name, event.target.value);
    }
  };

  return (
    <>
      <TextElement
        value={_.get(values, name)}
        size="sm"
        onBlur={() => setFieldTouched(name)}
        onChange={(event) => onChange(event)}
        {...otherProps}
      />

      <AppFormError
        visible={_.get(touched, name)}
        error={_.get(errors, name)}
      ></AppFormError>
    </>
  );
}

export default AppTextInput;
