import { Col, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";

const OFAnnouncementLayout = () => {
  return (
    <div className="h-100 min-vh-100" style={{ backgroundColor: "#e5f1f7" }}>
      <div className="p-5">
        <Outlet />
      </div>
    </div>
  );
};

export default OFAnnouncementLayout;
