import { AnnouncementConfirmationInput } from "../types";
import { ApiClient } from "./client";

let client = new ApiClient();

export default {
  getAppointmentDetails(code: string) {
    return client.get(`/v1/Appointment/${code}`);
  },
  confirmAppointmentDetails(input: AnnouncementConfirmationInput) {
    return client.post("/v1/Appointment/Confirm", input);
  },
};
