import { Row } from "react-bootstrap";

interface PageTitleProps {
  title: string;
  subTitle?: string;
}

const PageTitle = ({ title, subTitle }: PageTitleProps) => {
  return (
    <Row>
      <p className="h2">{title}</p>

      {subTitle && <p>{subTitle}</p>}
    </Row>
  );
};

export default PageTitle;
