import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetOwnerReminderDataQuery, useUpdateOwnerReminderDataMutation } from './OwnerRemindersSlice';
import { Button, Card, Col, Row, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import NoDataFound from './NoDataFound';
import DataSend from './DataSend';
import { OFReminderUpdate } from '../../types';

const OwnerReminders = () => {
    const { code: identifier } = useParams<{ code: string }>();
    const { data: reminder, isFetching, isError } = useGetOwnerReminderDataQuery(identifier!);
    const [updateOFReminder] = useUpdateOwnerReminderDataMutation();

    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [remarks, setRemarks] = useState<string>('');
    const [isPlanable, setIsPlanable] = useState<boolean>(false);
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
    const [updateStatus, setUpdateStatus] = useState<'idle' | 'success' | 'error'>('idle');

    if (isFetching) {
        return (
            <Row className="min-vh-100 d-flex align-items-center justify-content-center">
                <Col className="text-center">
                    <p className="h4">
                        <strong>Een klein momentje, de gegevens worden opgehaald...</strong>
                    </p>
                </Col>
            </Row>
        );
    }

    if (updateStatus === 'success') {
        return <DataSend />;
    }

    if (isError || !reminder) return <NoDataFound />;

    const { objectId, inspectionDate, address, postcode, placename, undecidedTextTwo, nietTeKeuren } = reminder;

    const formatDate = (datetimeStr: string) => {
        const date = new Date(datetimeStr);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const handleVersturenClick = () => {
        setButtonDisabled(true);

        const updateData: OFReminderUpdate = {
            objectId: objectId,
            notPlannableRemark: remarks,
            notPlannable: !isPlanable,
            expectedEndDate: selectedDate ? selectedDate.toISOString() : null,
        };

        updateOFReminder(updateData)
            .unwrap()
            .then(response => {
                setUpdateStatus('success');
            })
            .catch(error => {
                setUpdateStatus('error');
            })
            .finally(() => {
                setButtonDisabled(false);
            });
    };

    if (updateStatus === 'error') {
        return (
            <Row className="min-vh-100 d-flex align-items-center justify-content-center">
                <Col className="text-center">
                    <p className="h4">
                        <strong>Er is een fout opgetreden bij het bijwerken van de gegevens.</strong>
                    </p>
                </Col>
            </Row>
        );
    }

    return (
        <Row className="d-flex justify-content-center" style={{ marginTop: '10vh' }}>
            <Col xs={12} md={8} lg={6}>
                <Card style={{ width: '75%', maxWidth: '600px', minWidth: '550px', margin: 'auto', minHeight: '300px' }}>
                    <Card.Body style={{ overflowX: "auto", padding: '45px' }}>
                        {!nietTeKeuren ? (
                            <>
                                <p style={{ marginBottom: '30px' }}>
                                    Op {formatDate(inspectionDate)} hebben we geprobeerd uw lift te keuren op {address}, {postcode}, {placename}.
                                    Helaas konden we de keuring niet voltooien vanwege de volgende reden:
                                </p>
                                <p style={{ marginBottom: '30px' }}>
                                    '<em>{undecidedTextTwo}</em>'
                                </p>
                            </>
                        ) : (
                            <p style={{ marginBottom: '30px' }}>
                                Op {formatDate(inspectionDate)} hebben we uw lift op {address}, {postcode}, {placename} on hold gezet.
                                Graag bevestigen we de status van uw lift en of deze in de nabije toekomst ingepland kan worden voor een keuring.
                            </p>
                        )}
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '60%', margin: 'auto' }}>
                            {!nietTeKeuren ? (
                                <p style={{ marginBottom: '10px' }}>
                                    Kunt u aangeven of het bovenstaande is verholpen en de lift weer in te plannen is voor een keuring?
                                </p>
                            ) : (
                                <p style={{ marginBottom: '10' }}>
                                    Kan de lift van on hold afgehaald worden en is deze weer in te plannen voor een keuring?
                                </p>
                            )}
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '15px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
                                    <Button
                                        variant={isPlanable ? "success" : "outline-success"}
                                        onClick={() => setIsPlanable(true)}
                                        className="me-2"
                                        style={{ width: '45%' }}
                                    >
                                        Inplanbaar
                                    </Button>
                                    <Button
                                        variant={!isPlanable ? "danger" : "outline-danger"}
                                        onClick={() => setIsPlanable(false)}
                                        style={{ width: '45%' }}
                                    >
                                        Niet inplanbaar
                                    </Button>
                                </div>
                                {!isPlanable && (
                                    <>
                                        <Form.Group controlId="datePicker" style={{ width: '100%', position: 'relative' }}>
                                            <Form.Label>Wanneer zou de lift weer te keuren kunnen zijn?</Form.Label>
                                            <div className="date-picker-container" style={{ position: 'relative', width: '100%' }}>
                                                <DatePicker
                                                    wrapperClassName="dp-full-width"
                                                    selected={selectedDate}
                                                    onChange={(date) => setSelectedDate(date as Date | null)}
                                                    dateFormat="dd-MM-yyyy"
                                                    className="form-control dp-full-width"
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    dropdownMode="select"
                                                    minDate={new Date()}
                                                    maxDate={(() => {
                                                        const maxDate = new Date();
                                                        maxDate.setFullYear(maxDate.getFullYear() + 5);
                                                        return maxDate;
                                                    })()}
                                                    popperPlacement="bottom-end"
                                                />
                                                <FontAwesomeIcon
                                                    icon={faCalendarAlt}
                                                    style={{
                                                        position: 'absolute',
                                                        right: '10px',
                                                        top: '45%',
                                                        transform: 'translateY(-100%)',
                                                        pointerEvents: 'none',
                                                        color: '#aaa',
                                                    }}
                                                />
                                            </div>
                                        </Form.Group>
                                        <Form.Group controlId="remarks" style={{ width: '100%' }}>
                                            <Form.Label>Heeft u verdere opmerkingen?</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={4}
                                                value={remarks}
                                                onChange={(e) => setRemarks(e.target.value)}
                                                className="m--margin-bottom-30"
                                                maxLength={200}
                                            />
                                        </Form.Group>
                                    </>
                                )}
                            </div>
                        </div>
                    </Card.Body>
                    <Card.Footer className="text-right">
                        <Button
                            size="lg"
                            variant="success"
                            className="ms-4"
                            type="submit"
                            onClick={handleVersturenClick}
                            disabled={buttonDisabled}
                        >
                            Versturen aan Chex
                        </Button>
                    </Card.Footer>
                </Card>
            </Col>
        </Row>
    );
}

export default OwnerReminders;
