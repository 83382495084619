import { useField } from "formik";
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
import * as _ from "lodash";
import AppFormError from "./AppFormError";

interface TypeAheadProps {
  name: string;
  [x: string]: any;
  callback?: (mechanic: string) => void;
  options: string[];
}

const TypeAhead = ({
  name,
  options,
  callback,
  ...otherProps
}: TypeAheadProps) => {
  const [field, meta, helper] = useField(name);

  return (
    <>
      <Typeahead
        size={"sm"}
        id={name}
        selected={[field.value || ""]}
        onInputChange={(text) => helper.setValue(text)}
        onChange={(selected) => {
          if (selected?.length > 0) helper.setValue(selected[0] as any);

          if (callback) {
            callback(selected[0] as string);
          }
        }}
        options={options}
        emptyLabel={"Geen monteur gevonden"}
        {...otherProps}
      />

      <AppFormError visible={meta.touched} error={meta.error}></AppFormError>
    </>
  );
};

export default TypeAhead;
