import { Alert, Col, Row } from "react-bootstrap";
import Checkmark from "../../assets/checkmark.svg";

const ThankYou = () => {
  return (
    <>
      <Row className="mt-5 p-4">
        <Col className="text-center">
          <img
            className="img-fluid"
            src={Checkmark}
            alt="Afspraak opgeslagen"
            width={100}
          />
        </Col>
      </Row>

      <Row>
        <Col className="text-center">
          <p>Afspraak bevestigd!</p>
          <p>U kunt deze pagina nu sluiten.</p>
        </Col>
      </Row>
    </>
  );
};

export default ThankYou;
