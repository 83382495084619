import React, { useState, useEffect } from 'react';
import {
    Button,
    Card,
    Col,
    Form,
    Row,
    Table,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import NoDataFound from "./NoDataFound";
import {
    OFReminderDataOutput,
    OFReminderUpdate
} from "../../types";
import {
    useGetOFReminderDataQuery,
    useUpdateOFReminderDataMutation,
    useLazyGetReportDataQuery,
} from "./OFRemindersSlice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const OFReminders = () => {
    const { code: identifier } = useParams<{ code: string }>();
    const { data: reminders, isFetching, isError } = useGetOFReminderDataQuery(identifier!);

    const [planableState, setPlanableState] = useState<{ [key: number]: boolean }>({});
    const [remarks, setRemarks] = useState<{ [key: number]: string }>({});
    const [expectedEndDates, setExpectedEndDates] = useState<{ [key: number]: Date | null }>({});
    const [changedItems, setChangedItems] = useState<{ [key: number]: boolean }>({});
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const [updateOFReminders, { isLoading: isUpdatingOFReminders }] = useUpdateOFReminderDataMutation();
    const [fetchReportData, { data: reportData, isFetching: isFetchingReport, isError: isReportError }] = useLazyGetReportDataQuery();

    useEffect(() => {
        if (reminders && Array.isArray(reminders)) {
            const initialPlanableState: { [key: number]: boolean } = {};
            const initialRemarks: { [key: number]: string } = {};
            const initialExpectedEndDates: { [key: number]: Date | null } = {};
            const initialChanges: { [key: number]: boolean } = {};
            reminders.forEach(reminder => {
                initialPlanableState[reminder.objectId] = true;
                initialRemarks[reminder.objectId] = reminder.notPlannableRemarkOF || '';
                initialExpectedEndDates[reminder.objectId] = reminder.expectedEndDate
                    ? moment(reminder.expectedEndDate, "DD-MM-YYYY").set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).toDate()
                    : null;
                initialChanges[reminder.objectId] = false;
            });
            setPlanableState(initialPlanableState);
            setRemarks(initialRemarks);
            setExpectedEndDates(initialExpectedEndDates);
            setChangedItems(initialChanges);
        }
    }, [reminders]);

    const handlePlanableStateChange = (objectId: number, state: boolean) => {
        setPlanableState((prev) => ({
            ...prev,
            [objectId]: state
        }));

        setChangedItems((prev) => ({
            ...prev,
            [objectId]: true
        }));
    };

    const handleRemarkChange = (objectId: number, value: string) => {
        setRemarks((prev) => ({
            ...prev,
            [objectId]: value
        }));

        setChangedItems((prev) => ({
            ...prev,
            [objectId]: true
        }));
    };

    const handleExpectedEndDateChange = (objectId: number, date: Date | null) => {
        setExpectedEndDates((prev) => ({
            ...prev,
            [objectId]: date
        }));

        setChangedItems((prev) => ({
            ...prev,
            [objectId]: true
        }));
    };

    const getStatusDisplay = (status: number, nietTeKeuren: boolean) => {
        let statusText = '';

        switch (status) {
            case 1:
                statusText = 'Goedgekeurd';
                break;
            case 2:
                statusText = 'Afgekeurd';
                break;
            case 3:
                statusText = 'Onbeslist';
                break;
            default:
                statusText = '';
        }

        return { statusText, nietTeKeuren };
    };

    const handleDownloadReport = (objectId: number) => {
        fetchReportData(objectId)
            .unwrap()
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `report_${objectId}.pdf`;
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {
                console.error('Failed to download report:', error);
            });
    };

    if (isFetching) {
        return (
            <Row>
                <Col className="text-center">
                    <p className="h4">
                        <strong>Een klein momentje, de gegevens worden opgehaald...</strong>
                    </p>
                </Col>
            </Row>
        );
    }

    if (isError) return <NoDataFound />;

    if (!Array.isArray(reminders)) {
        return <div>Invalid data format.</div>;
    }

    const handleVersturenClick = () => {
        setButtonDisabled(true);

        const changedRemindersToUpdate: OFReminderUpdate[] = reminders
            .filter(reminder => changedItems[reminder.objectId])
            .map(reminder => ({
                objectId: reminder.objectId,
                notPlannableRemark: remarks[reminder.objectId] || '',
                notPlannable: planableState[reminder.objectId],
                expectedEndDate: expectedEndDates[reminder.objectId]?.toISOString() || null,
            }));

        updateOFReminders(changedRemindersToUpdate)
            .unwrap()
            .then(response => {
            })
            .catch(error => {
            })
            .finally(() => {
                setButtonDisabled(false);
            });
    };

    return (
        <Card>
            <Card.Body style={{ overflowX: "auto" }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <strong>Niet te keuren liftinstallaties voor {reminders[0].companyName}</strong>
                </div>
                <Table className="table table-bordered table-sm w-100 fixed-header">
                    <thead>
                        <tr>
                            <th style={{ backgroundColor: "#e5f1f7" }}>Object Nr. Chex</th>
                            <th style={{ backgroundColor: "#e5f1f7" }}>Adres</th>
                            <th style={{ backgroundColor: "#e5f1f7" }}>Postcode</th>
                            <th style={{ backgroundColor: "#e5f1f7" }}>Plaats</th>
                            <th style={{ backgroundColor: "#e5f1f7" }}>Installatie Nr.</th>
                            <th style={{ backgroundColor: "#e5f1f7" }}>Intern Nr. OHF</th>
                            <th style={{ backgroundColor: "#e5f1f7" }}>Opdracht Nr.</th>
                            <th style={{ backgroundColor: "#e5f1f7", width: '15%' }}>Opmerking Chex</th>
                            <th style={{ backgroundColor: "#e5f1f7", width: '15%' }}>Opmerking OHF</th>
                            <th style={{ backgroundColor: "#e5f1f7" }}>lifttype</th>
                            <th style={{ backgroundColor: "#e5f1f7", width: '4%' }}>Huidige status</th>
                            <th style={{ backgroundColor: "#e5f1f7" }}>Wacht sinds</th>
                            <th style={{ backgroundColor: "#e5f1f7", width: '5%', minWidth: 120 }}>Verwachte einddatum</th>
                            <th style={{ backgroundColor: "#e5f1f7", width: '10%', minWidth: 155 }}>Inplanbaar voor keuring</th>
                            <th style={{ backgroundColor: "#e5f1f7", width: '10%', minWidth: 100 }}>Rapport</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reminders.map((reminder: OFReminderDataOutput) => {
                            const { statusText, nietTeKeuren } = getStatusDisplay(parseInt(reminder.huidigeStatus), reminder.nietTeKeuren);
                            const displayDate = nietTeKeuren && reminder.onHoldDate ? reminder.onHoldDate : reminder.waitingSindsDate;

                            return (
                                <tr key={reminder.objectId}>
                                    <td>{reminder.objectId}</td>
                                    <td>{reminder.adres}</td>
                                    <td>{reminder.postcode}</td>
                                    <td>{reminder.plaatsnaam}</td>
                                    <td>{reminder.installatieNummerFabrikant}</td>
                                    <td>{reminder.nummerOnderhoudsfirma}</td>
                                    <td>{reminder.opdrachtNummer}</td>
                                    <td>{reminder.notPlannableRemarkChex}</td>
                                    <td>
                                        <Form.Control
                                            as="textarea"
                                            value={remarks[reminder.objectId]}
                                            onChange={(e) => handleRemarkChange(reminder.objectId, e.target.value)}
                                            style={{ resize: 'vertical', width: '100%' }}
                                            maxLength={255}
                                            rows={3}
                                        />
                                    </td>
                                    <td style={{ paddingRight: '10px' }}>{reminder.soortAfkeuring}</td>
                                    <td>
                                        <span className={statusText.includes('Afgekeurd') ? 'text-danger' : statusText.includes('Goedgekeurd') ? 'text-success' : 'text-warning'}>
                                            {statusText}
                                        </span>
                                        {nietTeKeuren && (
                                            <>
                                                {statusText && <span> + <span className="text-warning">On-Hold</span></span>}
                                                {!statusText && <span className="text-warning">On-Hold</span>}
                                            </>
                                        )}
                                    </td>
                                    <td style={{ paddingRight: '10px' }}>{displayDate}</td>
                                    <td>
                                        <DatePicker
                                            selected={expectedEndDates[reminder.objectId]}
                                            onChange={(date) => handleExpectedEndDateChange(reminder.objectId, date)}
                                            dateFormat="dd-MM-yyyy"
                                            className="form-control"
                                            showYearDropdown
                                            showMonthDropdown
                                            dropdownMode="select"
                                            minDate={new Date()}
                                            maxDate={(() => {
                                                const maxDate = new Date();
                                                maxDate.setFullYear(maxDate.getFullYear() + 5);
                                                return maxDate;
                                            })()}
                                        />
                                    </td>
                                    <td style={{ width: "5%" }}>
                                        <div className="bg-white btn-group">
                                            <Button
                                                variant={planableState[reminder.objectId] === false ? 'success' : 'outline-success'}
                                                onClick={() => handlePlanableStateChange(reminder.objectId, false)}
                                                style={{ width: '45%' }}
                                                className="rounded-0"
                                            >
                                                Inplanbaar
                                            </Button>
                                            <Button
                                                variant={planableState[reminder.objectId] === true ? 'danger' : 'outline-danger'}
                                                onClick={() => handlePlanableStateChange(reminder.objectId, true)}
                                                style={{ width: '55%' }}
                                            >
                                                Niet inplanbaar
                                            </Button>
                                        </div>
                                    </td>
                                    <td>
                                        {!nietTeKeuren && (
                                            <Button
                                                variant="link"
                                                onClick={() => handleDownloadReport(reminder.objectId)}
                                                disabled={isFetchingReport}
                                            >
                                                Open rapport
                                                <FontAwesomeIcon icon={faDownload} className="ms-2" />
                                            </Button>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Card.Body>
            <Card.Footer className="text-right">
                <Button
                    size="lg"
                    variant="success"
                    className="ms-4"
                    type="submit"
                    onClick={handleVersturenClick}
                    disabled={buttonDisabled || isUpdatingOFReminders}
                >
                    Versturen aan Chex
                </Button>
            </Card.Footer>
        </Card>
    );
};

export default OFReminders;
