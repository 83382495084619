import { statusTypes } from "../types";

export const getStatusColorByStatus = (value?: number): string => {
  if (value === 0 || value === undefined || value === null)
    return "outline-light text-black border border-dark";
  if (value === statusTypes.Akkoord) return "outline-success";
  if (value === statusTypes["On-hold"]) return "outline-warning";
  if (value === statusTypes["Niet akkoord"]) return "outline-danger";

  return "outline-secondary";
};
