import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast, { Toast } from "react-hot-toast";

const Notify = {
  success(title: string, message?: string) {
    return toast(
      (t) => (
        <ToastContent
          t={t}
          title={title}
          description={message}
          type="success"
        />
      ),
      {
        position: "top-right",
        style: {
          backgroundColor: "#61d345",
          color: "#fff",
        },
      }
    );
  },
  error(title: string, message?: string) {
    return toast(
      (t) => (
        <ToastContent t={t} title={title} description={message} type="error" />
      ),
      {
        position: "bottom-right",
        style: {
          backgroundColor: "#ff4b4b",
          color: "#fff",
        },
      }
    );
  },
  warning(title: string, message?: string) {
    return toast(
      (t) => (
        <ToastContent
          t={t}
          title={title}
          description={message}
          type="warning"
        />
      ),
      {
        position: "bottom-right",
        style: {
          backgroundColor: "orange",
          color: "#fff",
        },
      }
    );
  },
};

interface ToasterContentProps {
  t: Toast;
  title: string;
  description?: string;
  type: "success" | "warning" | "error";
}
const ToastContent = ({ t, title, description, type }: ToasterContentProps) => {
  return (
    <div className="d-flex">
      <div className="me-1">
        {type === "success" && (
          <FontAwesomeIcon
            icon={faCircleCheck}
            color="green"
            className="fa-icon"
            size={"2x"}
          />
        )}
        {type === "error" && (
          <FontAwesomeIcon
            icon={"crosshairs"}
            color="red"
            className="fa-icon"
            size={"2x"}
          />
        )}
        {type === "warning" && (
          <FontAwesomeIcon
            icon={"triangle-exclamation"}
            color="orange"
            className="fa-icon"
            size={"2x"}
          />
        )}
      </div>
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between">
          <h6 className="text-white">{title}</h6>
          <FontAwesomeIcon
            icon={"x"}
            color="orange"
            className="fa-icon"
            onClick={() => toast.dismiss(t.id)}
          />
        </div>
        {description && <span>{description}</span>}
      </div>
    </div>
  );
};

export default Notify;
