import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import * as _ from "lodash";
import { RadioButtonGroupItem } from "../../../types";

interface RadioButtonInputGroupProps {
  radioOptions: RadioButtonGroupItem[];
  getItemColor: (selectedRadioValue?: number) => string;
  value: number;
  name: string;
}

const RadioButtonInputGroupDisabled = ({
  radioOptions,
  value,
  getItemColor,
  name,
}: RadioButtonInputGroupProps) => {
  return (
    <>
      <ToggleButtonGroup
        value={value}
        type="radio"
        className="bg-white"
        name={name}
      >
        {radioOptions.map((radio, idx) => {
          return (
            <ToggleButton
              disabled={true}
              type="radio"
              variant={getItemColor(radio.value)}
              name="radio"
              value={radio.value}
              data-value={radio.value}
              checked={value === radio.value}
            >
              {radio.name}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </>
  );
};

export default RadioButtonInputGroupDisabled;
