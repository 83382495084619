import * as Yup from "yup";
import { statusTypes } from "../../types";

export const validationSchema = Yup.object().shape({
  identifier: Yup.string().required(),
  inspections: Yup.array().of(
    Yup.object().shape({
      id: Yup.number().required(),
      comment: Yup.string()
        .nullable()
        .label("Opmerkingen")
        .when("status", {
          is: statusTypes["On-hold"],
          then: Yup.string()
            .nullable()
            .required("Vul de on-hold reden hier in"),
        }),
    })
  ),
});
