/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  faCheck,
  faHistory,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { saveAs } from "file-saver";
import { Formik } from "formik";
import * as _ from "lodash";
import * as moment from "moment";
import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Nav,
  OverlayTrigger,
  Row,
  Tab,
  Table,
  Tabs,
  Tooltip,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import AppTextAreaInput from "../../components/forms/AppTextAreaInput";
import AppTextInput from "../../components/forms/AppTextInput";
import MultiDatePicker from "../../components/forms/MultiDatePicker";
import TypeAhead from "../../components/forms/TypeAhead";
import RadioButtonInputGroupDisabled from "../../components/forms/controls/RadioButtonGroupInputDisabled";
import {
  AnnouncementRowIV,
  UpdateOFAnnouncement,
  changeTypes,
  periodTypes,
  statusTypes,
} from "../../types";
import Message from "../../utility/notifications/Message";
import Notify from "../../utility/notifications/Notify";
import { getStatusColorByStatus } from "../../utility/statusColors";
import NoDataFound from "./NoDataFound";
import {
  useGetMechanicListQuery,
  useGetOFAnnouncementDataQuery,
  useLazyDownloadExcelExportQuery,
  useLazyGetPhoneNumberForMechanicQuery,
  useUpdateOFAnnouncementMutation,
} from "./OFAnnouncementsSlice";
import StatusElement from "./StatusElement";
import { validationSchema } from "./validationSchema";

const OFAnnouncements = () => {
  const navigate = useNavigate();

  const [allItemsExpanded, setAllItemsExpanded] = useState(false);
  const [expandedItems, setExpandedItems] = useState<number[]>([]);
  const [inspectionIV, setInspectionIV] = useState<AnnouncementRowIV[]>();

  const { code: identifier } = useParams();

  const { data, isFetching, isError } = useGetOFAnnouncementDataQuery(
    identifier!
  );

  const { data: mechanics } = useGetMechanicListQuery(identifier!);

  const [updateAnnouncement, { isLoading: isUpdatingAnnouncement }] =
    useUpdateOFAnnouncementMutation();

  const [downloadExport, { isLoading: isDownloading }] =
    useLazyDownloadExcelExportQuery();

  useEffect(() => {
    document.title = "Chex - ";
    if (data?.data.periodType === 0) {
      document.title += data?.data.period;
    } else {
      document.title += "WK " + data?.data.period;
    }
  }, [data]);

  const [getMechanicPhoneNumber] = useLazyGetPhoneNumberForMechanicQuery();

  const toggleInspectionHistory = (inspectionId: number) => {
    const expandedItemToModify = [...expandedItems] as number[];

    if (
      expandedItemToModify.length > 0 &&
      expandedItemToModify.includes(inspectionId)
    ) {
      const index = expandedItemToModify.indexOf(inspectionId);
      expandedItemToModify.splice(index, 1);
      setExpandedItems(expandedItemToModify);
    } else {
      expandedItemToModify.push(inspectionId);
      setExpandedItems(expandedItemToModify);
    }
  };

  const getPhoneNumberForMechanic = (
    mechanic: string,
    setFieldValue: any,
    name: string
  ) => {
    getMechanicPhoneNumber({
      identifier: identifier!,
      mechanicName: mechanic,
    })
      .unwrap()
      .then((data) => {
        if (data) {
          setFieldValue(name, data);
        }
      });
  };

  const showCheckedNotification = () => {
    Notify.success("Regel gemarkeerd als gelezen");
  };

  const handleSubmit = async (input: UpdateOFAnnouncement) => {
    await updateAnnouncement(input)
      .unwrap()
      .then(() => {
        navigate(
          `/ofannouncement/confirmation/${identifier}/${data?.data.periodType}`
        );
      });
  };

  useEffect(() => {
    if (data) {
      const result = data.data.weeks.flatMap((u) =>
        u.days.flatMap((u) => u.inspections)
      );

      if (result.length > 0) {
        const flatInspectionList = result.map(
          (inspection) =>
            ({
              comment: inspection.comment,
              id: inspection.id,
              historyItemId: inspection.historyItemId,
              status: inspection.status,
              mechanic: inspection.mechanic,
              mechanicPhoneNumber: inspection.mechanicPhoneNumber,
              alternativeDates: inspection?.alternativeDates?.map(
                (date: any) => new Date(Date.parse(date))
              ),
              isProcessedByOF: inspection?.isProcessedByOF,
              internalNumber: inspection?.internalNumber,
              orderNumber: inspection?.orderNumber,
              changeType: inspection?.changeType,
            } as AnnouncementRowIV)
        );

        setInspectionIV(flatInspectionList);
      }
    }
  }, [data]);

  if (isError) return <NoDataFound />;

  if (isFetching) {
    return (
      <Row>
        <Col className="text-center">
          <p className="h4">
            <strong>Een klein momentje, de gegevens worden opgehaald...</strong>
          </p>
        </Col>
      </Row>
    );
  } else {
    return (
      <div className="mb-5">
        {data?.data.hasMissingInspectorInformation && (
          <Alert variant="warning">
            <span className="text-black font-weight-bold">
              Let op: Er zijn één of meerdere dagen waar nog een monteur en
              tel-nr op ontbreekt. (Indien één monteur de dag hele komt, dan is
              één keer invullen voldoende).
            </span>
          </Alert>
        )}

        <Formik
          enableReinitialize={true}
          initialValues={{
            identifier: identifier!,
            inspections: inspectionIV,
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({
            handleSubmit,
            values,
            setFieldValue,
            errors,
            setFieldError,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              {values.inspections &&
                values?.inspections.filter(
                  (u) => u.status === 0 && u.changeType !== changeTypes.Deleted
                ).length > 0 &&
                !data?.data.hasUnmarkedItems &&
                !data?.data.isFirstLoad && (
                  <Alert variant="warning">
                    <span className="text-black font-weight-bold">
                      Let op: nog niet bij alle keuringen heeft u een reactie
                      gegeven.
                    </span>
                  </Alert>
                )}
              <Card>
                <Card.Body style={{ overflowX: "auto" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <strong>
                      {data!.data.maintenanceFirmName} -{" "}
                      {periodTypes[data!.data.periodType]}
                    </strong>
                    <Button
                      variant="primary"
                      onClick={() => {
                        window.open(
                          `/onderhoudsfirma/overzicht/${data?.data.maintenanceFirmAnnouncementId}`,
                          "_blank"
                        );
                      }}
                    >
                      Naar overzicht
                    </Button>
                  </div>

                  <Tab.Container
                    defaultActiveKey={data?.data.weeks[0].weekNumber}
                  >
                    <Nav variant="pills">
                      {data?.data?.weeks?.map((week) => {
                        const hasChanges = week.days.some((day) =>
                          day.inspections
                            .filter(
                              (u) =>
                                u.isHistory === false &&
                                u.changeType !== changeTypes.Deleted
                            )
                            .some(
                              (inspection) =>
                                inspection.isProcessedByOF === false ||
                                inspection.status === statusTypes.Reset
                            )
                        );

                        return (
                          <Nav.Item>
                            <Nav.Link
                              eventKey={week.weekNumber}
                              className={hasChanges ? "pulsing-red-tab" : ""}
                            >
                              <span className="red-tab">{`Week ${week.weekNumber}`}</span>
                            </Nav.Link>
                          </Nav.Item>
                        );
                      })}
                    </Nav>

                    <Tab.Content>
                      {data?.data?.weeks?.map((week) => {
                        return (
                          <Tab.Pane eventKey={week.weekNumber}>
                            <Table className="table table-bordered table-sm w-100 fixed-header">
                              <>
                                <thead>
                                  <tr>
                                    <th style={{ backgroundColor: "#e5f1f7" }}>
                                      <span>
                                        {allItemsExpanded ? (
                                          <a
                                            title="Alles uitklappen"
                                            href="javascript:void(0)"
                                            onClick={() => {
                                              setAllItemsExpanded(false);
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faHistory}
                                              color="orange"
                                              className="fa-icon"
                                            />
                                          </a>
                                        ) : (
                                          <a
                                            title="Alles uitklappen"
                                            href="javascript:void(0)"
                                            className="ms-2"
                                            onClick={() => {
                                              setAllItemsExpanded(true);
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faHistory}
                                              color="orange"
                                              className="fa-icon"
                                            />
                                          </a>
                                        )}
                                      </span>
                                    </th>
                                    <th style={{ backgroundColor: "#e5f1f7" }}>
                                      Tijd
                                    </th>
                                    <th style={{ backgroundColor: "#e5f1f7" }}>
                                      Inspecteur
                                    </th>
                                    <th style={{ backgroundColor: "#e5f1f7" }}>
                                      Objectnr Chex
                                    </th>
                                    <th style={{ backgroundColor: "#e5f1f7" }}>
                                      Objecttype / keuringstype
                                    </th>
                                    <th
                                      style={{
                                        backgroundColor: "#e5f1f7",
                                        minWidth: 160,
                                      }}
                                    >
                                      Adres
                                    </th>
                                    <th style={{ backgroundColor: "#e5f1f7" }}>
                                      Postcode
                                    </th>
                                    <th style={{ backgroundColor: "#e5f1f7" }}>
                                      Plaats
                                    </th>
                                    <th style={{ backgroundColor: "#e5f1f7" }}>
                                      Install.nr OHF
                                    </th>
                                    <th style={{ backgroundColor: "#e5f1f7" }}>
                                      Intern nr. OHF
                                      <OverlayTrigger
                                        overlay={
                                          <Tooltip id="tooltip-disabled">
                                            Invullen indien nodig
                                          </Tooltip>
                                        }
                                      >
                                        <a
                                          href="javascript:void(0)"
                                          className="ms-2"
                                        >
                                          <FontAwesomeIcon
                                            icon={faInfoCircle}
                                            color="primary"
                                            className="fa-icon"
                                          />
                                        </a>
                                      </OverlayTrigger>
                                    </th>
                                    <th style={{ backgroundColor: "#e5f1f7" }}>
                                      Opdrachtnr.
                                      <OverlayTrigger
                                        overlay={
                                          <Tooltip id="tooltip-disabled">
                                            Invullen indien nodig
                                          </Tooltip>
                                        }
                                      >
                                        <a
                                          href="javascript:void(0)"
                                          className="ms-2"
                                        >
                                          <FontAwesomeIcon
                                            icon={faInfoCircle}
                                            color="primary"
                                            className="fa-icon"
                                          />
                                        </a>
                                      </OverlayTrigger>
                                    </th>
                                    <th style={{ backgroundColor: "#e5f1f7" }}>
                                      Monteur
                                    </th>
                                    <th style={{ backgroundColor: "#e5f1f7" }}>
                                      Tel-nr
                                    </th>
                                    <th style={{ backgroundColor: "#e5f1f7" }}>
                                      Opmerkingen Chex
                                    </th>
                                    <th style={{ backgroundColor: "#e5f1f7" }}>
                                      Opmerkingen OHF
                                    </th>
                                    <th style={{ backgroundColor: "#e5f1f7" }}>
                                      Reactie OHF
                                    </th>
                                  </tr>
                                </thead>

                                {week.days.map((day) => {
                                  const dayHasNonHiddenInspections =
                                    day.inspections.filter(
                                      (u) =>
                                        u.changeType !== changeTypes.Deleted ||
                                        u.isProcessedByOF === false
                                    ).length === 0;
                                  return (
                                    <>
                                      <tbody>
                                        <tr
                                          style={{
                                            backgroundColor: "#f4f5f8",
                                            display:
                                              !dayHasNonHiddenInspections ||
                                              allItemsExpanded
                                                ? ""
                                                : "none",
                                          }}
                                        >
                                          <th></th>
                                          <th colSpan={14}>
                                            {day.date} - {day.inspector}
                                            {day.inspections.filter(
                                              (u) => !u.isHistory
                                            ).length > 0 && (
                                              <span className="float-right">
                                                <Button
                                                  size="sm"
                                                  variant="success"
                                                  onClick={() => {
                                                    day.inspections.forEach(
                                                      (i, index) => {
                                                        if (!i.isHistory) {
                                                          setFieldValue(
                                                            `inspections[${values.inspections?.findIndex(
                                                              (u) =>
                                                                u.id === i.id &&
                                                                i.changeType !==
                                                                  changeTypes.Deleted
                                                            )}].status`,
                                                            statusTypes.Akkoord
                                                          );

                                                          setFieldValue(
                                                            `inspections[${values.inspections?.findIndex(
                                                              (u) =>
                                                                u.id === i.id &&
                                                                i.changeType !==
                                                                  changeTypes.Deleted
                                                            )}].isProcessedByOF`,
                                                            true
                                                          );
                                                        }
                                                      }
                                                    );
                                                  }}
                                                >
                                                  Hele dag akkoord
                                                </Button>
                                              </span>
                                            )}
                                          </th>
                                        </tr>

                                        {day.inspections.map((inspection) => {
                                          let nameBasePart = "";

                                          if (inspection.isHistory) {
                                            nameBasePart = `inspections[${values.inspections?.findIndex(
                                              (u) =>
                                                u.historyItemId ===
                                                inspection.historyItemId
                                            )}]`;
                                          } else {
                                            nameBasePart = `inspections[${values.inspections?.findIndex(
                                              (u) =>
                                                u.id === inspection.id &&
                                                u.historyItemId === null
                                            )}]`;
                                          }

                                          return (
                                            <>
                                              <tr
                                                key={`tr-${inspection.id}`}
                                                style={{
                                                  backgroundColor:
                                                    !_.get(
                                                      values,
                                                      `${nameBasePart}.isProcessedByOF`
                                                    ) &&
                                                    inspection.changeType !==
                                                      changeTypes.Deleted &&
                                                    !inspection.isHistory
                                                      ? "rgba(37, 150, 190, 0.6)"
                                                      : inspection.isHistory
                                                      ? "rgba(255,160,160,0.8)"
                                                      : inspection.changeType ===
                                                        changeTypes.Deleted
                                                      ? "rgba(255,160,160,0.8)"
                                                      : "",
                                                  display:
                                                    inspection.changeType ===
                                                      changeTypes.Deleted &&
                                                    inspection.isProcessedByOF &&
                                                    !allItemsExpanded
                                                      ? "none"
                                                      : "",
                                                }}
                                              >
                                                <td>
                                                  {inspection.history &&
                                                    inspection.history.length >
                                                      0 && (
                                                      <a
                                                        href="javascript:void(0)"
                                                        className="me-1"
                                                        onClick={() =>
                                                          toggleInspectionHistory(
                                                            inspection.id
                                                          )
                                                        }
                                                      >
                                                        <FontAwesomeIcon
                                                          icon={faHistory}
                                                          color="orange"
                                                          className="fa-icon"
                                                        />
                                                      </a>
                                                    )}
                                                </td>
                                                <td className="text-nowrap">
                                                  <>
                                                    {inspection &&
                                                      !_.get(
                                                        values,
                                                        `${nameBasePart}.isProcessedByOF`
                                                      ) && (
                                                        <a
                                                          href="javascript:void(0)"
                                                          className="me-1"
                                                          onClick={() => {
                                                            setFieldValue(
                                                              `${nameBasePart}.isProcessedByOF`,
                                                              true
                                                            );
                                                            showCheckedNotification();
                                                          }}
                                                        >
                                                          <FontAwesomeIcon
                                                            icon={faCheck}
                                                            color="green"
                                                            className="fa-icon ms-2"
                                                            title="Markeer als verwerkt"
                                                          />
                                                        </a>
                                                      )}
                                                  </>
                                                  <span>
                                                    {moment
                                                      .default(
                                                        inspection.startTime
                                                      )
                                                      .format("HH:mm")}{" "}
                                                    -{" "}
                                                    {moment
                                                      .default(
                                                        inspection.endTime
                                                      )
                                                      .format("HH:mm")}
                                                  </span>
                                                </td>
                                                <td>
                                                  {inspection.inspectorName}
                                                </td>
                                                <td>{inspection.objectId}</td>
                                                <td>
                                                  {
                                                    inspection.objectAndInspectionType
                                                  }
                                                </td>

                                                <td>
                                                  {inspection.objectAddress}
                                                </td>
                                                <td>
                                                  {inspection.objectPostalcode}
                                                </td>
                                                <td>{inspection.objectCity}</td>
                                                <td>
                                                  {
                                                    inspection.objectInstallationNumber
                                                  }
                                                </td>

                                                <td>
                                                  <AppTextInput
                                                    disabled={
                                                      inspection.isHistory ||
                                                      inspection.changeType ===
                                                        changeTypes.Deleted
                                                    }
                                                    name={`${nameBasePart}.internalNumber`}
                                                  />
                                                </td>
                                                <td>
                                                  <AppTextInput
                                                    disabled={
                                                      inspection.isHistory ||
                                                      inspection.changeType ===
                                                        changeTypes.Deleted
                                                    }
                                                    name={`${nameBasePart}.orderNumber`}
                                                  />
                                                </td>
                                                <td>
                                                  <TypeAhead
                                                    disabled={
                                                      inspection.isHistory ||
                                                      inspection.changeType ===
                                                        changeTypes.Deleted
                                                    }
                                                    options={mechanics || []}
                                                    name={`${nameBasePart}.mechanic`}
                                                    callback={(
                                                      mechanic: string
                                                    ) =>
                                                      getPhoneNumberForMechanic(
                                                        mechanic,
                                                        setFieldValue,
                                                        `${nameBasePart}.mechanicPhoneNumber`
                                                      )
                                                    }
                                                  />
                                                </td>
                                                <td>
                                                  <AppTextInput
                                                    disabled={
                                                      inspection.isHistory ||
                                                      inspection.changeType ===
                                                        changeTypes.Deleted
                                                    }
                                                    name={`${nameBasePart}.mechanicPhoneNumber`}
                                                  />
                                                </td>
                                                <td>
                                                  <Form.Control
                                                    as="textarea"
                                                    size="sm"
                                                    style={{
                                                      fontWeight:
                                                        inspection.isProcessedByOF
                                                          ? "regular"
                                                          : "900",
                                                    }}
                                                    disabled={true}
                                                    rows={1}
                                                    value={
                                                      inspection.commentChex
                                                    }
                                                  />
                                                </td>
                                                <td>
                                                  <>
                                                    <AppTextAreaInput
                                                      disabled={
                                                        inspection.isHistory ||
                                                        inspection.changeType ===
                                                          changeTypes.Deleted
                                                      }
                                                      name={`${nameBasePart}.comment`}
                                                    />

                                                    {}
                                                  </>
                                                </td>
                                                <td>
                                                  <>
                                                    <>
                                                      <StatusElement
                                                        disabled={
                                                          inspection.isLocked ||
                                                          inspection.isHistory ||
                                                          inspection.changeType ===
                                                            changeTypes.Deleted
                                                        }
                                                        name={`${nameBasePart}.status`}
                                                        key={`status-${inspection.id}`}
                                                        callback={() => {
                                                          setFieldValue(
                                                            `${nameBasePart}.alternativeDates`,
                                                            null
                                                          );

                                                          setFieldValue(
                                                            `${nameBasePart}.isProcessedByOF`,
                                                            true
                                                          );

                                                          setTimeout(() =>
                                                            setFieldTouched(
                                                              `${nameBasePart}.comment`,
                                                              true
                                                            )
                                                          );
                                                        }}
                                                      />

                                                      {_.get(
                                                        values,
                                                        `${nameBasePart}.status`
                                                      ) ===
                                                        statusTypes[
                                                          "Niet akkoord"
                                                        ] && (
                                                        <>
                                                          <br></br>
                                                          <span>
                                                            Selecteer eventuele
                                                            alternatieve data
                                                          </span>
                                                          <br></br>
                                                          <MultiDatePicker
                                                            disabled={
                                                              inspection.isHistory ||
                                                              inspection.changeType ===
                                                                changeTypes.Deleted
                                                            }
                                                            name={`${nameBasePart}.alternativeDates`}
                                                          />
                                                        </>
                                                      )}
                                                    </>
                                                  </>
                                                </td>
                                              </tr>

                                              {inspection?.history?.map(
                                                (historyInspection, index) => {
                                                  return (
                                                    <tr
                                                      key={`history-inspection-${historyInspection.id}`}
                                                      className="history-inspection"
                                                      style={{
                                                        display:
                                                          expandedItems.filter(
                                                            (u) =>
                                                              u ===
                                                              inspection.id
                                                          ).length > 0 ||
                                                          allItemsExpanded
                                                            ? ""
                                                            : "none",
                                                      }}
                                                    >
                                                      <td></td>
                                                      <td className="text-nowrap">
                                                        {historyInspection.startTime !==
                                                          inspection.startTime && (
                                                          <>
                                                            <span className="me-2">
                                                              {moment
                                                                .default(
                                                                  historyInspection.startTime
                                                                )
                                                                .format(
                                                                  "DD-MM-yyyy"
                                                                )}{" "}
                                                              //
                                                            </span>
                                                            <span>
                                                              {moment
                                                                .default(
                                                                  historyInspection.startTime
                                                                )
                                                                .format(
                                                                  "HH:mm"
                                                                )}{" "}
                                                              -{" "}
                                                              {moment
                                                                .default(
                                                                  historyInspection.endTime
                                                                )
                                                                .format(
                                                                  "HH:mm"
                                                                )}
                                                            </span>
                                                          </>
                                                        )}
                                                      </td>
                                                      <td className="text-nowrap">
                                                        {
                                                          historyInspection.inspectorName
                                                        }
                                                      </td>
                                                      <td className="text-nowrap">
                                                        {inspection.objectId}
                                                      </td>
                                                      <td className="text-nowrap">
                                                        {
                                                          inspection.objectAndInspectionType
                                                        }
                                                      </td>
                                                      <td className="text-nowrap">
                                                        {
                                                          inspection.objectAddress
                                                        }
                                                      </td>
                                                      <td className="text-nowrap">
                                                        {
                                                          inspection.objectPostalcode
                                                        }
                                                      </td>
                                                      <td className="text-nowrap">
                                                        {inspection.objectCity}
                                                      </td>
                                                      <td className="text-nowrap">
                                                        {
                                                          inspection.objectInstallationNumber
                                                        }
                                                      </td>

                                                      <td className="text-nowrap">
                                                        {
                                                          inspection.internalNumber
                                                        }
                                                      </td>
                                                      <td className="text-nowrap">
                                                        {inspection.orderNumber}
                                                      </td>
                                                      <td></td>
                                                      <td className="text-nowrap">
                                                        {inspection.mechanic}
                                                      </td>
                                                      <td className="text-nowrap">
                                                        {
                                                          historyInspection.commentChex
                                                        }
                                                      </td>
                                                      <td className="text-nowrap">
                                                        {
                                                          historyInspection.comment
                                                        }
                                                      </td>
                                                      <td>
                                                        {historyInspection.status !==
                                                          statusTypes.Reset &&
                                                          historyInspection.status !==
                                                            undefined && (
                                                            <span>
                                                              <RadioButtonInputGroupDisabled
                                                                name={`status-disabled-${historyInspection.id}-${historyInspection.inspectorId}`}
                                                                value={
                                                                  historyInspection.status
                                                                }
                                                                getItemColor={
                                                                  getStatusColorByStatus
                                                                }
                                                                radioOptions={[
                                                                  {
                                                                    name: "Akkoord",
                                                                    value:
                                                                      statusTypes.Akkoord,
                                                                  },
                                                                  {
                                                                    name: "Niet akkoord",
                                                                    value:
                                                                      statusTypes[
                                                                        "Niet akkoord"
                                                                      ],
                                                                  },
                                                                  {
                                                                    name: "On-hold",
                                                                    value:
                                                                      statusTypes[
                                                                        "On-hold"
                                                                      ],
                                                                  },
                                                                ]}
                                                              />
                                                            </span>
                                                          )}
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )}
                                            </>
                                          );
                                        })}
                                      </tbody>
                                    </>
                                  );
                                })}
                              </>
                            </Table>
                          </Tab.Pane>
                        );
                      })}
                    </Tab.Content>
                  </Tab.Container>

                  <Table borderless>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            border: 0,
                            padding: 0,
                            paddingRight: 4,
                            paddingBottom: 2,
                            height: 30,
                            textAlign: "right",
                            width: 95,
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faHistory}
                            color="orange"
                            className="fa-icon"
                          />
                        </td>
                        <td
                          style={{
                            border: 0,
                            padding: 0,
                            paddingBottom: 2,
                            height: 30,
                            verticalAlign: "middle",
                            paddingLeft: 10,
                          }}
                        >
                          Druk in regels op het historie icoon om de
                          onderliggende wijzigingshistorie te weergeven.
                        </td>
                      </tr>

                      <tr>
                        <td
                          style={{
                            border: 0,
                            padding: 0,
                            paddingBottom: 2,
                            verticalAlign: "middle",
                          }}
                        >
                          <Button
                            style={{ width: 100 }}
                            size="sm"
                            variant="outline"
                            className="me-1 btn btn-outline-light text-black border border-dark"
                            type="button"
                          >
                            Reset
                          </Button>
                        </td>
                        <td
                          style={{
                            border: 0,
                            padding: 0,
                            paddingBottom: 2,
                            paddingLeft: 10,
                          }}
                        >
                          De status wordt leeg gemaakt, met uitzondering van
                          keuringen die eerder akkoord zijn gegeven.
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: 0, padding: 0, paddingBottom: 2 }}>
                          <Button
                            style={{ width: 100 }}
                            size="sm"
                            variant="success"
                            className="me-1"
                            type="button"
                          >
                            Akkoord
                          </Button>
                        </td>
                        <td
                          style={{
                            border: 0,
                            padding: 0,
                            paddingBottom: 2,
                            verticalAlign: "middle",
                            paddingLeft: 10,
                          }}
                        >
                          De installatie kan op de voorgestelde datum gekeurd
                          worden. Akkoord gegeven keuringen kunnen in deze lijst
                          niet meer worden aangepast. Mocht dit onverhoopt toch
                          nodig zijn, stuurt u dan alstublieft een mail naar{" "}
                          <a href="mailto:planning@chex.nl">planning@chex.nl</a>
                          .
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: 0, padding: 0, paddingBottom: 2 }}>
                          <Button
                            style={{ width: 100 }}
                            size="sm"
                            variant="danger"
                            className="me-1"
                            type="button"
                          >
                            Niet akkoord
                          </Button>
                        </td>
                        <td
                          style={{
                            border: 0,
                            padding: 0,
                            paddingBottom: 2,
                            verticalAlign: "middle",
                            paddingLeft: 10,
                          }}
                        >
                          De installatie kan wel worden gekeurd, echter niet op
                          de voorgestelde dag.
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: 0, padding: 0, paddingBottom: 2 }}>
                          <Button
                            style={{ width: 100 }}
                            size="sm"
                            variant="warning"
                            className="me-1"
                            type="button"
                          >
                            On-hold
                          </Button>
                        </td>
                        <td
                          style={{
                            border: 0,
                            padding: 0,
                            paddingBottom: 2,
                            verticalAlign: "middle",
                            paddingLeft: 10,
                          }}
                        >
                          De installatie kan momenteel niet worden gekeurd.
                          Bijvoorbeeld door storing, niet meer in contract, enz.
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: 0, padding: 0, paddingBottom: 2 }}>
                          <span
                            style={{
                              width: 100,
                              height: 27,
                              backgroundColor: "rgba(37, 150, 190, 0.6)",
                              display: "inline-block",
                              verticalAlign: "text-top",
                            }}
                            className="me-1"
                          ></span>
                        </td>
                        <td
                          style={{
                            border: 0,
                            padding: 0,
                            paddingBottom: 2,
                            verticalAlign: "middle",
                            paddingLeft: 10,
                          }}
                        >
                          Aanvulling, wijziging, of nieuwe opmerking. Deze dient
                          afgevinkt te worden door middel van het groene vinkje
                          (
                          <FontAwesomeIcon
                            icon={faCheck}
                            color="green"
                            className="fa-icon ms-2"
                            title="Markeer als verwerkt"
                          />{" "}
                          ) aan het begin van de regel.
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                  <div className="mt-1"></div>
                </Card.Body>
                <Card.Footer className="text-right">
                  {isDownloading ? (
                    <span>Excel wordt gedownload...</span>
                  ) : (
                    <Button
                      size="lg"
                      variant="primary"
                      onClick={async () =>
                        downloadExport(identifier!)
                          .unwrap()
                          .then((payload) => {
                            saveAs(
                              payload,
                              `Excel_${periodTypes[data!.data.periodType]}_${
                                data?.data.period
                              }.xlsx`
                            );
                          })
                          .catch(() => {
                            Message(
                              "Oeps",
                              "Er is iets fout gegaan. Probeer het later opnieuw.",
                              "error"
                            );
                          })
                      }
                    >
                      Exporteer (Excel)
                    </Button>
                  )}

                  <Button
                    size="lg"
                    variant="success"
                    className="ms-4"
                    type="submit"
                    disabled={isUpdatingAnnouncement}
                  >
                    Versturen aan Chex
                  </Button>
                </Card.Footer>
              </Card>
            </form>
          )}
        </Formik>
      </div>
    );
  }
};

export default OFAnnouncements;
