import { nl } from "yup-locales";
import * as Yup from "yup";

import "./App.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Announcement from "./pages/Announcements/Announcement";
import Index from "./pages/Announcements";
import ThankYou from "./pages/Announcements/ThankYou";
import OFThankYou from "./pages/OFAnnouncements/ThankYou";
import OFAnnouncements from "./pages/OFAnnouncements";
import CustomerAnnouncementLayout from "./components/layouts/CustomerAnnouncementLayout";
import OFAnnouncementLayout from "./components/layouts/OFAnnouncementLayout";
import Overview from "./pages/OFAnnouncements/Overview";
import OFReminderLayout from "./components/layouts/OFReminderLayout";
import OFReminders from "./pages/OFReminders";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import OwnerReminderLayout from "./components/layouts/OwnerReminderLayout";
import OwnerReminders from "./pages/OwnerReminders";

Yup.setLocale(nl);

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<CustomerAnnouncementLayout />}>
                    <Route path="/" element={<Index />} />
                    <Route path="/announcement" element={<Announcement />} />
                    <Route path="/announcement/confirmation" element={<ThankYou />} />
                </Route>

                <Route element={<OFAnnouncementLayout />}>
                    <Route path="/onderhoudsfirma/:code" element={<OFAnnouncements />} />
                    <Route
                        path="/ofannouncement/confirmation/:code/:periodType"
                        element={<OFThankYou />}
                    />
                    <Route
                        path="/onderhoudsfirma/overzicht/:code"
                        element={<Overview />}
                    />
                </Route>

                <Route element={<OFReminderLayout />}>
                    <Route path="/ofreminder/:code" element={<OFReminders />} />
                </Route>

                <Route element={<OwnerReminderLayout />}>
                    <Route path="/ownerreminder/:code" element={<OwnerReminders />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
