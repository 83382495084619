import { apiGenerator } from "../../redux/api/apiGenerator";
import {
    OwnerReminderDataOutput,
    OFReminderUpdate
} from "../../types";

const generatedAPI = apiGenerator
    .enhanceEndpoints({
        addTagTypes: ["OwnerReminder"],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getOwnerReminderData: builder.query<OwnerReminderDataOutput, string>({
                query: (identifier: string) => ({
                    url: `/v1/OwnerReminder/${identifier}`,
                    method: "GET",
                }),
                providesTags: ["OwnerReminder"],
            }),
            updateOwnerReminderData: builder.mutation<void, OFReminderUpdate>({
                query: (input) => ({
                    url: "/v1/OwnerReminder",
                    method: "POST",
                    body: input,
                }),
                invalidatesTags: ["OwnerReminder"],
            }),
        }),
        overrideExisting: true,
    });

export const {
    useGetOwnerReminderDataQuery,
    useUpdateOwnerReminderDataMutation,
} = generatedAPI;
