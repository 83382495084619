import { useFormikContext } from "formik";
import AppFormError from "./AppFormError";
import { Form } from "react-bootstrap";
import * as _ from "lodash";

function AppTextAreaInput({ name, required = false, ...otherProps }) {
  const { setFieldTouched, setFieldValue, values, errors, touched } =
    useFormikContext();

  console.log("errors", _.get(errors, name));

  return (
    <>
      <Form.Control
        {...otherProps}
        as="textarea"
        size="sm"
        rows={1}
        value={_.get(values, name)}
        onBlur={() => setFieldTouched(name)}
        onChange={(event) => setFieldValue(name, event.target.value)}
      />

      <AppFormError visible={true} error={_.get(errors, name)}></AppFormError>
    </>
  );
}

export default AppTextAreaInput;
