import { Form } from "react-bootstrap";

interface TextElementProps {
  value: string;
  [x: string]: any;
}

const TextElement = ({ value, ...otherProps }: TextElementProps) => {
  return <Form.Control value={value} size="sm" {...otherProps} />;
};

export default TextElement;
