import ChexLogo from ".././../assets/chex-logo.png";

import { Col, Container, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";

interface Props {
  children?: JSX.Element[] | JSX.Element;
}

const CustomerAnnouncementLayout = ({ children }: Props) => {
  return (
    <Container>
      <Row className="mt-5 p-4">
        <Col className="">
          <div className="text-center">
            <img
              className="img-fluid"
              src={ChexLogo}
              alt="Chex Liftkeuringen B.V."
              width={150}
            />
          </div>
          {children}
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
};

export default CustomerAnnouncementLayout;
