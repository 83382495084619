import { apiGenerator } from "../../redux/api/apiGenerator";
import {
  AnnouncementOFOverviewDetailsData,
  AnnouncementOFOverviewHeaderData,
  getMechanicPhoneNumber,
  OFAnnouncementDataOutput,
  SearchAnnounceOFOverviewDetailsInput,
  UpdateOFAnnouncement,
} from "../../types";

const generatedAPI = apiGenerator
  .enhanceEndpoints({
    addTagTypes: ["OFAnnouncement"],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getOFAnnouncementData: builder.query<OFAnnouncementDataOutput, string>({
        query: (identifier: string) => ({
          url: `/v1/OFAnnouncement/${identifier}`,
          method: "GET",
        }),
        providesTags: ["OFAnnouncement"],
      }),
      getOFAnnouncementOverviewHeader: builder.query<
        AnnouncementOFOverviewHeaderData,
        string
      >({
        query: (identifier: string) => ({
          url: `v1/OFAnnouncement/MaintenanceFirm/Details/${identifier}`,
          method: "GET",
        }),
        providesTags: ["OFAnnouncement"],
      }),
      getOFAnnouncementOverviewData: builder.query<
        AnnouncementOFOverviewDetailsData,
        SearchAnnounceOFOverviewDetailsInput
      >({
        query: (input) => ({
          url: `v1/OFAnnouncement/Overview/${input.identifier}`,
          params: input,
          method: "GET",
        }),
        providesTags: ["OFAnnouncement"],
      }),
      updateOFAnnouncement: builder.mutation<void, UpdateOFAnnouncement>({
        query: (input) => ({
          url: "/v1/OFAnnouncement",
          method: "POST",
          body: input,
        }),
        invalidatesTags: ["OFAnnouncement"],
      }),
      downloadExcelExport: builder.query<any, string>({
        query: (identifier) => ({
          url: `/v1/OFAnnouncement/export/${identifier}`,
          method: "GET",
          headers: {
            "Content-Type": "application/xml; charset:utf-8",
          },
          responseHandler: (response) => response.blob(),
        }),
      }),
      getMechanicList: builder.query<string[], string>({
        query: (identifier: string) => ({
          url: `/v1/OFAnnouncement/GetMechanics/${identifier}`,
          method: "GET",
        }),
        providesTags: ["OFAnnouncement"],
      }),
      getPhoneNumberForMechanic: builder.query<any, getMechanicPhoneNumber>({
        query: (data: getMechanicPhoneNumber) => ({
          url: `/v1/OFAnnouncement/GetPhoneNumberForMechanic/${data.identifier}/${data.mechanicName}`,
          method: "GET",
          responseHandler: "text",
        }),
        providesTags: ["OFAnnouncement"],
      }),
    }),
    overrideExisting: true,
  });

export const {
  useGetOFAnnouncementDataQuery,
  useUpdateOFAnnouncementMutation,
  useLazyDownloadExcelExportQuery,
  useGetMechanicListQuery,
  useLazyGetPhoneNumberForMechanicQuery,
  useGetOFAnnouncementOverviewDataQuery,
  useGetOFAnnouncementOverviewHeaderQuery,
} = generatedAPI;
