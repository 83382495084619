import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Checkmark from "../../assets/checkmark.svg";
import { periodTypes } from "../../types";

const ThankYou = () => {
  const { code: identifier, periodType } = useParams();

  return (
    <>
      <Row className="mt-5 p-4">
        <Col className="text-center">
          <img
            className="img-fluid"
            src={Checkmark}
            alt="Gegevens opgeslagen"
            width={125}
          />
        </Col>
      </Row>

      <Row>
        <Col className="text-center">
          <h4>Gegegevens opgeslagen</h4>
          <p>De ingevoerde gegevens zijn opgeslagen.</p>
          <p>
            Dit is een interactief overzicht, dat betekent dat je op elk moment
            verder kan waar je gebleven bent of eventuele
            wijzigingen/aanvullingen kan verwerken. Klik hiervoor opnieuw op de
            link in de mail of klik{" "}
            <a href={`/onderhoudsfirma/${identifier}`}>hier.</a>
          </p>

          <p className="text-decoration-underline">
            Let op: deze link is uniek voor deze periode (
            {periodTypes[parseInt(periodType || "0")]}). Je ontvangt bij de
            volgende periode een nieuwe unieke link.
          </p>
        </Col>
      </Row>
    </>
  );
};

export default ThankYou;
