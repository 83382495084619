import { useFormikContext } from "formik";
import DatePicker from "react-multi-date-picker";
import * as _ from "lodash";
import DatePanel from "react-multi-date-picker/plugins/date_panel";

interface MultiDatePickerProps {
  name: string;
  maxDate?: Date;
  [x: string]: any;
}

const MultiDatePicker = ({ name, maxDate, ...rest }: MultiDatePickerProps) => {
  const { setFieldValue, values } = useFormikContext();

  const hideWeekendCells = (props: any) => {
    const { date, ...otherProps } = props;

    if (date.isoWeekday() === 6 || date.isoWeekday() === 7) {
      return <div style={{ display: "none" }} {...otherProps} />;
    }

    return <div {...otherProps} />;
  };

  return (
    <DatePicker
      {...rest}
      displayWeekNumbers
      weekDays={["Zo", "Ma", "Di", "Wo", "Do", "Vr", "Za"]}
      weekNumber="Wk"
      format={"DD/MM/YYYY"}
      weekStartDayIndex={1}
      minDate={new Date()}
      maxDate={maxDate}
      mapDays={({ date }) => {
        let isWeekend = [0, 6].includes(date.weekDay.index);

        if (isWeekend)
          return {
            disabled: true,
            style: { color: "#ccc" },
          };
      }}
      multiple
      value={_.get(values, name)}
      onChange={(value) => {
        const list = value as any;

        const array = [] as any;

        if (list) {
          list.forEach((element: any) => {
            array.push(new Date(element.toDate()));
          });
        }

        setFieldValue(name, array);
      }}
      plugins={[<DatePanel sort="date" />]}
      className="form-control form-control-sm"
    />
  );
};

export default MultiDatePicker;
