import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { apiGenerator } from "./api/apiGenerator";

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  blacklist: ["api"],
};

const rootReducer = combineReducers({
  [apiGenerator.reducerPath]: apiGenerator.reducer,
});

export { rootPersistConfig, rootReducer };
