import { faUserClock, faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import { useState } from "react";
import { Button, Card, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useParams } from "react-router-dom";
import TextElement from "../../components/forms/controls/TextElement";
import { AnnouncementOFOverviewDetailsData } from "../../types";
import Notify from "../../utility/notifications/Notify";
import {
  useGetOFAnnouncementOverviewDataQuery,
  useGetOFAnnouncementOverviewHeaderQuery,
} from "./OFAnnouncementsSlice";

const Overview = () => {
  const [showCompleted, setShowCompleted] = useState(false);

  const { code: identifier } = useParams();

  const formatDate = (dateString: string) => {
    if (dateString) {
      return DateTime.fromISO(dateString).toFormat("dd-MM-yyyy");
    }
    return "";
  };

  const { data: overviewHeader } = useGetOFAnnouncementOverviewHeaderQuery(
    identifier || "",
    { skip: !identifier }
  );

  document.title = "Chex - Totaal overzicht";

  const { data: overviewData, refetch } = useGetOFAnnouncementOverviewDataQuery(
    {
      identifier: identifier || "",
      showCompleted: showCompleted,
    },
    { skip: !identifier, pollingInterval: 30000 }
  );

  const paginationOptions = {
    paginationSize: 15,
    pageStartIndex: 1,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    prePageText: "Vorige",
    nextPageText: "Volgende",
    showTotal: false,
    hideSizePerPage: true,
  };

  const headerStyle = {
    backgroundColor: "rgb(229, 241, 247)",
  } as ColumnDescription["headerStyle"];

  const columns = [
    {
      dataField: "period",
      text: "Periode",
      sort: true,
      headerStyle,
      formatter: (
        _: any,
        record: AnnouncementOFOverviewDetailsData["data"][0]
      ) => (
        <>
          <span style={{ marginRight: 5 }}>{record.period}</span>

          {record.numberOfInspectionsToReview > 0 && (
            <OverlayTrigger
              key="warning-tooltip"
              placement="top"
              overlay={
                <Tooltip id={`tooltip-warning`}>
                  Nog niet bij alle keuringen heeft u een reactie gegeven.
                </Tooltip>
              }
            >
              <FontAwesomeIcon
                icon={faWarning}
                color="red"
                className="fa-icon mr-1"
              />
            </OverlayTrigger>
          )}

          {record.hasCompletedDaysWithoutMechanic && (
            <OverlayTrigger
              key="user-clock-tooltip"
              placement="top"
              overlay={
                <Tooltip id={`tooltip-user-clock`}>
                  Er zijn één of meerdere dagen waar nog een monteur en tel-nr
                  op ontbreekt.{" "}
                </Tooltip>
              }
            >
              <FontAwesomeIcon
                icon={faUserClock}
                color="orange"
                className="fa-icon"
              />
            </OverlayTrigger>
          )}
        </>
      ),
    },
    {
      dataField: "lastModificationChex",
      text: "Laatste wijziging Chex",
      sort: true,
      formatter: (cell: string) => formatDate(cell),
      headerStyle,
    },
    {
      dataField: "lastSaveTime",
      text: "Laatst opgeslagen op",
      sort: true,
      formatter: (cell: string) => formatDate(cell),
      headerStyle,
    },
    {
      dataField: "numberOfInspectionsToReview",
      text: "Te beoordelen",
      headerStyle,
    },
    {
      dataField: "totalNumberOfInspections",
      text: "Aantal keuringen",
      headerStyle,
    },
    {
      dataField: "actions",
      text: "Acties",
      headerStyle,
      formatter: (
        _: any,
        record: AnnouncementOFOverviewDetailsData["data"][0]
      ) => (
        <Button
          variant="primary"
          className="me-1 btn btn-outline-light text-white"
          type="button"
          size="sm"
          onClick={() =>
            window.open(`/onderhoudsfirma/${record.identifier}`, "_blank")
          }
        >
          Open
        </Button>
      ),
    },
  ] as ColumnDescription<any, any>[];

  return (
    <Card>
      <Card.Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            width: "100%",
          }}
        >
          <h4 style={{ margin: 0, flexGrow: 1 }}>
            {overviewHeader?.data?.name}
          </h4>

          <div style={{ marginRight: 10 }}>
            <Form.Check
              type={"checkbox"}
              id={`check-toon-afgerond`}
              label={"Toon afgerond"}
              onChange={(e) => {
                setShowCompleted(e.target.checked);
              }}
            />
          </div>

          <Button
            variant="primary"
            className="me-1 btn btn-outline-light text-white"
            type="button"
            size="lg"
            onClick={() => refetch()}
          >
            Ververs
          </Button>
        </div>
      </Card.Header>
      <Card.Body style={{ overflowX: "auto" }}>
        <BootstrapTable
          data={overviewData?.data || []}
          columns={columns}
          keyField="dataField"
          pagination={paginationFactory(paginationOptions)}
        />
      </Card.Body>
    </Card>
  );
};

export default Overview;
