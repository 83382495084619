import { apiGenerator } from "../../redux/api/apiGenerator";
import {
    OFReminderDataOutput,
    OFReminderUpdate
} from "../../types";

const generatedAPI = apiGenerator
    .enhanceEndpoints({
        addTagTypes: ["OFReminder"],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getOFReminderData: builder.query<OFReminderDataOutput, string>({
                query: (identifier: string) => ({
                    url: `/v1/OFReminder/${identifier}`,
                    method: "GET",
                }),
                providesTags: ["OFReminder"],
            }),
            getReportData: builder.query<any, number>({
                query: (identifier: number) => ({
                    url: `/v1/OFReminder/report/${identifier}`,
                    method: "GET",
                    responseType: 'blob',
                    headers: {
                        "Content-Type": "application/pdf",
                    },
                    responseHandler: (response) => response.blob(),
                }),
                providesTags: ["OFReminder"],
            }),
            updateOFReminderData: builder.mutation<void, OFReminderUpdate[]>({
                query: (input) => ({
                    url: "/v1/OFReminder",
                    method: "POST",
                    body: input,
                }),
                invalidatesTags: ["OFReminder"],
            }),
        }),
        overrideExisting: true,
    });

export const {
    useGetOFReminderDataQuery,
    useLazyGetReportDataQuery,
    useUpdateOFReminderDataMutation,
} = generatedAPI;
